import React, { Component } from 'react';

class Progress extends Component {
	
	checkProgress = () => {
		const { maxLength, currentValue } = this.props
		
		const currentProgress = currentValue.length
		let progress = 'low'
		
		if ( currentProgress <= maxLength / 3 ) {
			return progress = 'low'
		}

		if ( currentProgress <= maxLength / 2 ) {
			return progress = 'medium'
		}

		if ( currentProgress <= maxLength ) {
			return progress = 'good'
		}

		if ( currentProgress > maxLength ) {
			return progress = 'low'
		}

		return progress
	}

	render() {
		const { maxLength, currentValue } = this.props

		return (
			<progress 
			 className={ `seo__progress seo__progress--${this.checkProgress()}` } 
			 max={ maxLength } 
			 value={ currentValue.length }
			>
			</progress>
		)
	}
}

export default Progress;
