import React, { Component } from 'react'

class Snippet extends Component {
	constructor(props) {
		super(props)

		this.state = {
			defaultDesc: 'Please provide a meta description by editing the snippet below. If you don’t, Google will try to find a relevant part of your post to show in the search results.'
		}
	}

	render() {
		const { siteTitle, seoTitle, seoDesc } = this.props
		const { defaultDesc } = this.state

		return (
			<div className="Snippet">
				<p className="Snippet__title">{ seoTitle ? seoTitle + siteTitle : '' }</p>
				<p className="Snippet__slug">https://stickearn.com/blog/your-blog-slug</p>
				<p className="Snippet__desc">{ seoDesc ? seoDesc : defaultDesc }</p>
			</div>
		)
	}
}

export default Snippet
