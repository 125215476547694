import React from 'react';
import './index.css';

const Header = () => {
	return(
		<header className="Header">
		    <div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="Header__title text-center py-5">
							SEO <span className="text-primary">Stick</span><span className="text-secondary">Earn</span>
						</h1>
					</div>
				</div>
		    </div>
		</header>
	)
}

export default Header
