import React, { Component } from 'react'

class Collapse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggle: false
        }
    }

    CollapseIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path fill="#000" d="M4.22 10.78l-1.44 1.44 12.5 12.5.72.69.72-.7 12.5-12.5-1.44-1.43L16 22.56 4.22 10.78z"/></svg>
    )

    handleClick = () => {
        this.setState({ toggle: !this.state.toggle })
    }

    render() {
        const { title, children } = this.props
        const { toggle } = this.state

        return(
            <div className="Collapse">
                <div className="Collapse__heading position-relative" onClick={ this.handleClick }>
                    <h4 className="Collapse__heading-title d-inline-block my-0">{ title }</h4>
                    <span className={`Collapse__heading-icon Collapse__heading-icon--${toggle ? 'show' : 'hide'} mx-3`}>{ this.CollapseIcon() }</span>
                </div>
                <div className={ `Collapse__content Collapse__content--${toggle ? 'show' : 'hide'}` }>
                    { children }
                </div>
            </div>
        )
    }
}

export default Collapse
