import React from 'react';

import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import Seo from './Seo'

function App() {
  return (
    <div className="App">
      <Header />
      <main className="main">
        <Seo />
      </main>
      <Footer />
    </div>
  );
}

export default App;
