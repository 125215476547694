import React from 'react';
import './index.css'

const Footer = () => (
	<footer className="Footer">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<p className="Footer__copyright text-center mb-0 mt-5 py-4 ">
						<small>&copy; {(new Date().getFullYear())} StickEarn</small>
					</p>
				</div>
			</div>
		</div>
	</footer>
)

export default Footer;
