import React, { Component } from 'react'
import copy from 'copy-to-clipboard'
import './index.css'

class CopyToClipBoard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showNotify: false,
			closeNotify: 3000
		}
	}

	CopyIcon = () => (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.867 477.867"><defs/><path d="M341.333 85.333H51.2c-28.277 0-51.2 22.923-51.2 51.2v290.133c0 28.277 22.923 51.2 51.2 51.2h290.133c28.277 0 51.2-22.923 51.2-51.2V136.533c0-28.277-22.923-51.2-51.2-51.2zM358.4 426.667c0 9.426-7.641 17.067-17.067 17.067H51.2c-9.426 0-17.067-7.641-17.067-17.067V136.533c0-9.426 7.641-17.067 17.067-17.067h290.133c9.426 0 17.067 7.641 17.067 17.067v290.134z"/><path d="M426.667 0h-307.2c-28.277 0-51.2 22.923-51.2 51.2 0 9.426 7.641 17.067 17.067 17.067S102.4 60.626 102.4 51.2s7.641-17.067 17.067-17.067h307.2c9.426 0 17.067 7.641 17.067 17.067v307.2c0 9.426-7.641 17.067-17.067 17.067s-17.067 7.641-17.067 17.067 7.641 17.067 17.067 17.067c28.277 0 51.2-22.923 51.2-51.2V51.2c0-28.277-22.923-51.2-51.2-51.2z"/></svg>
	)


	showNotification = () => {
		// show notification
		this.setState({ showNotify: !this.state.showNotify })

		// clear notification after 3s
		setTimeout(() => { 
			this.setState({ showNotify: !this.state.showNotify })
		}, this.state.closeNotify)
	}

	handleClick = () => {
		copy(this.props.textToCopy)
		this.showNotification()
	}

	render() {
		const { showNotify } = this.state

		return (
			<div className="CopyToClipBoard">
				<button className="CopyToClipBoard__btn" onClick={this.handleClick}>{this.CopyIcon()}</button>
				<div className={ `${showNotify ? 'CopyToClipBoard__notify CopyToClipBoard__notify--active' : 'CopyToClipBoard__notify' }`}>
					<span className="CopyToClipBoard__text">"Copied to clipboard!"</span>
				</div>
			</div>
		)
	}
}

export default CopyToClipBoard
