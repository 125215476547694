import React, { Component } from 'react';
import Progress from './Progress'
import Snippet from './Snippet'
import CopyClipBoard from '../CopyToClipBoard'
import Collapse from '../Collapse'

class Seo extends Component {
	constructor() {
		super()

		this.state = {
			siteTitle: ' | StickEarn',
			seoTitle: '',
			seoDesc: '',
			seoKeyword: '',
			maxTitle: 70,
			maxDesc: 155,
			maxKeyword: 255,
			progressTitle: 'low'
		}
	}

	handleChange = e => {
		const { target: {name, value} } = e

		this.setState({ [name]: value })
	}

	render() {
		const { 
			siteTitle, 
			seoTitle,
			seoDesc,
			seoKeyword,
			progressTitle, 
			maxTitle,
			maxDesc,
			maxKeyword
		} = this.state

		return (
			<div className="Seo py-3">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="Seo__snippetEditor p-4">
								<h4 className="Seo__title pb-3">Edit Snippet</h4>
								<div className="form-group position-relative">
									<label htmlFor="seoTitle">Title</label>
									<input id="seoTitle" className="form-control" type="text" name="seoTitle" value={seoTitle} onChange={this.handleChange} />
									<Progress progress={ progressTitle } maxLength={maxTitle - siteTitle.length} currentValue={ seoTitle } />
									<CopyClipBoard textToCopy={`${seoTitle}${siteTitle}`} />
								</div>
								<div className="form-group position-relative">
									<label htmlFor="seoDesc">Meta description</label>
									<textarea id="seoDesc" className="form-control" rows="10" name="seoDesc" value={seoDesc} onChange={this.handleChange}></textarea>
									<Progress progress={ progressTitle } maxLength={maxDesc} currentValue={ seoDesc } />
									<CopyClipBoard textToCopy={seoDesc} />
								</div>
								<Collapse title="Extra">
									<div className="form-group position-relative mt-4">
										<label htmlFor="seoKeyword">Meta keyword</label>
										<textarea id="seoKeyword" className="form-control" rows="10" name="seoKeyword" value={seoKeyword} onChange={this.handleChange}></textarea>
										<Progress progress={ progressTitle } maxLength={maxKeyword} currentValue={ seoKeyword } />
										<CopyClipBoard textToCopy={seoKeyword} />
									</div>
								</Collapse>
							</div>
						</div>
						<div className="col-md-6">
							<div className="Seo__snippetPreview py-4">
								<h4 className="Seo__title pb-3">Preview as</h4>
								<Snippet siteTitle={siteTitle} seoTitle={seoTitle} seoDesc={seoDesc} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Seo;
